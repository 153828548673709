<template>
  <form @submit.prevent="submitHandler">
    <div class="card hoverable">
      <div class="card-content">
        <span class="card-title">Авторизация</span>
        <div class="block_inputs">
          <div class="input-field">
            <i class="material-icons prefix">account_circle</i>
            <input
              id="username"
              type="text"
              v-model.trim="username"
              :class="{
                invalid: $v.username.$dirty && !$v.username.required
              }"
            />
            <label for="username">Логин</label>
            <small
              class="helper-text invalid"
              v-if="$v.username.$dirty && !$v.username.required"
              >Введите имя пользователя</small
            >
          </div>
          <div class="input-field">
            <i class="material-icons prefix">lock</i>
            <input
              id="password"
              type="password"
              v-model.trim="password"
              :class="{
                invalid: $v.password.$dirty && !$v.password.required
              }"
            />
            <label for="password">Пароль</label>
            <small
              class="helper-text invalid"
              v-if="$v.password.$dirty && !$v.password.required"
              >Введите пароль</small
            >
          </div>
        </div>
      </div>
      <div class="card-action">
        <button class="btn waves-effect waves-light btn_submit" type="submit">
          Войти
          <i class="material-icons right">send</i>
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import API from '../utils/API';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'Login',
  data: () => ({
    username: '',
    password: ''
  }),
  validations: {
    username: { required },
    password: { required }
  },
  methods: {
    async submitHandler() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }

      const formData = {
        username: this.username,
        password: this.password
      };

      const kabinetAPI = new API();
      const response = await kabinetAPI.login(formData);
      if (response.status === 200) {
        const answer = await response.json();
        localStorage.setItem('auth', answer.token);
        this.$router.push('/').catch(() => {});
      } else {
        const answer = await response.json();
        /* global M */
        M.toast({
          html: `${answer.non_field_errors}`,
          classes: 'red darken-3',
          displayLength: 5000
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
form {
  width: 100%;
  max-width: 450px;
  padding-bottom: 50px;
  .card-title {
    margin-bottom: 30px;
  }
  .block_inputs {
    margin-top: 30px;
  }
  .btn {
    width: 100%;
    &.btn_submit {
      background-color: #1e9277;
    }
  }
}
</style>
